/* 
Instructions to test this
1. Open dev tools
2. Cmd-Shift-P and type "rendering"
3. In the rendering tab (which opens at the very bottom of console), 
   look for "Emulate CSS media type"
4. Choose "print"
*/

@media print {
  .block-image,
  .block-video,
  .block-embed,
  .block-drawing,
  .block-gallery {
    break-inside: avoid;
  }

  * {
    text-shadow: none !important;
  }

  div.doc-root {
    overflow: visible !important;
  }
  * {
    background-attachment: unset !important;
  }

  [data-doc-background-element],
  [data-doc-background-element-present-mode] {
    height: 100% !important;
    width: 100% !important;
    background-size: 100% 100% !important;
  }

  .logo {
    width: 80px !important;
    height: 80px !important;
  }
  div.doc-content-wrapper {
    padding-bottom: 0 !important;
  }

  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    overflow: visible !important;
  }

  [data-print-hidden],
  .chakra-menu__menu-list,
  .__prosemirror-dev-tools__ {
    display: none !important;
  }

  @page {
    size: landscape;
    margin: 0;
  }
}
