.cardWiggle {
  animation: shake 0.7s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  position: relative;
  left: 0;
  top: 0;
}

@keyframes shake {
  10%,
  90% {
    left: -1px;
    top: 1px;
  }

  20%,
  80% {
    left: 2px;
    top: -1px;
  }

  30%,
  50%,
  70% {
    left: -4px;
    top: 0px;
  }

  40%,
  60% {
    left: 4px;
    top: 1px;
  }
}

.coachbarCursor .cursorBlinkEffect {
  animation: blink-animation 1.5s infinite;
}
@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading-pulse-animation {
  0% {
    border-top: 6px solid var(--chakra-colors-trueblue-300);
  }

  50% {
    border-top: 6px solid var(--chakra-colors-gray-300);
  }

  100% {
    border-top: 6px solid var(--chakra-colors-trueblue-300);
  }
}

// For starfield background on 404
@keyframes rotation-asteroid {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes jiggle-sal {
  from {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(0px);
  }
}

.bouncing-loader {
  div {
    width: 6px;
    height: 6px;
    margin: 8px 2px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }

  div:nth-child(2) {
    animation-delay: 0.2s;
  }

  div:nth-child(3) {
    animation-delay: 0.4s;
  }
}
